<template>
<div  class="ttcontainer">

  <h2> 
        <button
            class="buttonAchtung"
            @click="backToHome()"
        >
          EventList   <==
        </button>
  </h2>

  <h1> EVENT view   ------>    <span> {{eventName}}</span></h1>

  <Event 
    :currentEvent="namedEvent(eventName)"
    v-if="!!ok" 
  ></Event>
        
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";
import Event from '@/components/Event.vue'; // @ is an alias to /src



export default {
  name: 'EventView',
  props: ["eventName"],

  components: {
    Event,
  },

  data() {
    return {
      ok: false,
    }
  },

  computed:{
     ...mapGetters([
       'eventList',
       'namedEvent'
     ]),
  },
  
  async mounted() {
    this.currentEvent =  await this.getEventByName(this.eventName)
    this.ok = true
  },

   methods: {
    
    ...mapActions([
       'getEventByName',
    ]),

    backToHome: function () {
      this.$router.push('/') 
    }

  },

}

</script>

<style scoped lang="scss">

div {
  // color: blueviolet;
  font-size: 1rem;
}
  
#eventContainer {
        position: relative;
        display: flex;
        background-color: black;
}

#eventList {
    font-size: 2rem; 
    position: relative;
    max-height: 80vh;
    overflow-x: scroll;
    overflow-y: hidden;
  .eventName {
    min-width: 20rem;
  }

  div {
     padding-top: 1vh;
  }

}

</style>
