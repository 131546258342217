<template>
<div  class="ttcontainer eventContainer">

	<div class="eventWrapper">
		        <div class="eventName" >   {{ currentEvent.name }} </div>

              <div @click="toggleShowSettings(currentEvent)" class="click"> [show Settings] </div>

              <div class="eventHS" v-if="currentEvent.scores">
                <div> HighScore:</div>
                   
                    <div @click="toggleShowHS(currentEvent)" class="click"> [show Top10] </div>
                    
                    <div @click="deleteHighScores(currentEvent.name)" class="click"> [clear] </div>
                    <div @click="newHighScores(currentEvent.name)" class="click"> [new] </div>
                    
                    <div @click="showDeleteOneWin=!showDeleteOneWin" class="click">
                      delete: 
                    </div>
                  <div class="deleteOneContainer" v-if="!!showDeleteOneWin">
                    <div v-for="nr in nrlist" :key="nr">
                      <div v-on:click="deleteHsId(currentEvent, nr)"> {{nr}} </div>
                    </div>
					        </div>
              </div>
	</div>
  <InsertHs :event_id="currentEvent.id"></InsertHs>
      <div>
        <h2> HighscoreList </h2>
      </div>
  <ve-table :columns="columns" :table-data="currentEvent.scores"
        id="eventList"
  />


    <div v-if="!!showHsWin" id="scores">
      <TopTen :eventName="currentEvent.name" ></TopTen>
    </div>
    <div v-if="!!showSettings" id="eventSettingsContainer">
      <textarea v-model="newEventSettings" rows="8" cols="40"></textarea>
        <pre>{{ newEventSettings | pretty }}</pre>
    </div>

  </div>	
</template>


<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";
import { VeTable } from "vue-easytable";
    import TopTen from '@/components/highscore/TopTen.vue'; // @ is an alias to /src
    import InsertHs from '@/components/highscore/InsertHs.vue'; // @ is an alias to /src

export default {
  name: 'Event',

  components: {
    TopTen,
    VeTable,
    InsertHs,
  },
  props: ["currentEvent"],
  
  data() {
    return {
      ok: false,

      showDeleteOneWin: false,
      showEditWin : false,
      showHsWin: false,
      showSettings: false,
      new_Event_name : "",
      new_Event_initscore : 0, 
      newEventName: "",
      showMore: false,
      newEventSettings: "",

      columns: [
        { field: "id", key: "a", title: "id" },
        { field: "event_id", key: "b", title: "Event ID" },
        { field: "player_name", key: "c", title: "name" },
        { field: "player_vorname", key: "d", title: "VorName" },
        { field: "score", key: "e", title: "score" },
        {field: "",
            key: "f",
            title: "Action",
            width: "",
            center: "left",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <span>
                        <button
                            class="buttonAchtung"
                            on-click={() => this.deleteScoreByListId(rowIndex)}
                        >
                          [ X ]
                        </button>

                      </span>
                  );
              },
          },

    ],

    }
  },

  filters: {
    pretty: function(value) {
 //     return JSON.stringify(value, null, 2);
    return JSON.stringify(JSON.parse(value), null, 2);
    }
  },

   computed:{
     ...mapGetters([
     ]),
   },
   async mounted() {
   },

   methods: {
    
    ...mapActions([
      'newHighScores',
      'deleteHighScores',
      'deleteScoreById',
    ]),

    toggleShowSettings: function () {
      this.showSettings = !this.showSettings
      this.newEventSettings = JSON.stringify(this.currentEvent)
      console.log(" -> toggleShowSettings -> " + this.showSettings)
    },

    deleteScoreByListId: function(listIndex) {
      this.deleteScoreById(this.currentEvent.scores[listIndex])
    }

  },

}

</script>

<style  lang="scss" scoped>

  div {
    color: #35d4c7;
    font-size: 1rem;
  }
#eventContainer {
        position: relative;
        display: flex;
}

#eventList {
  // padding: 2vw 2vw;   
    font-size: 1rem; 
    margin: 2vw; 
    background-color: honeydew;
    position: relative;
  .eventName {
    min-width: 20rem;
  }
  
  .eventWrapper {
  	display: flex;
  }

  .deleteOneContainer {
    padding: 0;
    div {
       padding: 0;
    }
  }

  .eventHS {
    display: flex;
  }

}

TopTen {
  color: black;
}

#eventSettingsContainer {
  display: flex;
}

.click {
  cursor: pointer;
}

</style>
