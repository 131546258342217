<template>
  <div id="HsAdminComponent" class="highscore">
		
    <h1> add new score  </h1>

    <div id="inputContainer" class="inputContainer">
      
      <div class="flex" >
        <div class="inputName"> SCORE </div>
        <input maxlength="60" name="new_vorname" type="text" 
                v-model="new_score"  />
      </div>     

            <div class="inputName"> Vorname * </div>
            <input maxlength="60" name="new_vorname" type="text" 
                v-model="new_vorname"  />
            <div class="inputName"> Nachname * </div>
            <input maxlength="60" name="new_nachname" type="text" 
                v-model="new_nachname"  />  
            <div class="inputName"> Firma *  </div>
            <input maxlength="60" name="new_comp" type="text" 
                v-model="new_comp" />  
            <div class="inputName">E-Mail *  </div>
            <input maxlength="60" name="new_email" type="text" 
                v-model="new_email"  />  
    </div>

    <el-button 
      type="" 
      @click="sendData()"
      class="OKButton">
      OK
    </el-button>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";

export default {
  name: 'InsertHs',
  props: ["event_id"],

  components: {
  },

   data () {
    return {
      new_score : "",
      new_vorname : "", 
      new_nachname : "",
      new_email : "",
      new_comp : "",
    }
  },

  computed:{
    ...mapGetters([
    ]),
  },

   created() {
     this.clearInput();
  },

  mounted() {
  },

  methods: {

    ...mapActions([
      'sendNewScoreToServer',
    ]),

    sendData: function(_id) {
          let hsForm = {
              "score" : this.new_score,
              "player_email" : this.new_email,
              "player_vorname": this.new_vorname,
              "player_name": this.new_nachname,
              "organisation": this.new_comp,
              "event_id": this.event_id,
              "fakeScore": false,
          }
          this.sendNewScoreToServer(hsForm)
          console.log("Form gesendet"); 
          this.clearInput();
        },

        clearInput : function() {
          this.new_score = 123; 
          this.new_nachname = "FE NAME test"; 
          this.new_vorname = "FE VN test"; 
          this.new_email = "FE E test"; 
          this.new_comp = "FE C test"; 
        },


  },

}

</script>

<style lang="scss" >

.inputContainer {
  display: flex;
}
</style>
