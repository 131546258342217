<template>
  <div  class="ttcontainer eventContainer">

	  <input class="csInput" v-model="newEventName" placeholder="new Event Name ..... "> 
    <button class="button" id="newEventButton" @click="callNewEventByName()">
      make new Event
    </button>

  </div>	
</template>


<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";

export default {
  name: 'NewEvent',

  components: {
  },
  
  data() {
    return {
      newEventName: "",
    }
  },

   computed:{
     ...mapGetters([
     ]),
   },
   async mounted() {
   },

   methods: {
    
    ...mapActions([
      'newEventByName',
    ]),

    callNewEventByName: async function() {
      await this.newEventByName(this.newEventName)
    }

  },

}

</script>

<style  lang="scss" scoped>
.csInput{
      padding: 1vh 1vw;
    font-size: 1.1rem;
    margin: 1vw;
}
</style>
