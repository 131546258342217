<template>
<div  class="ttcontainer">

    <NewEvent></NewEvent>
    <h1> EVENT - LIST : </h1>
        <ve-table :columns="columns" :table-data="eventList"
        id="eventList"
        v-if="!!ok" 
         />
        
  </div>
</template>


<script>
import {mapGetters} from "vuex";
import { mapActions } from "vuex";
import NewEvent from '@/components/NewEvent.vue'; // @ is an alias to /src
import { VeTable } from "vue-easytable";

export default {
  name: 'EventList',

  components: {
    NewEvent,
    VeTable,
  },

  data() {
    return {
      ok: false,
      columns: [
        { field: "id", key: "c", title: "id" },
        { field: "name", key: "a", title: "name" },
        { field: "customer", key: "f", title: "customer" },
        { field: "score_type_id", key: "b", title: "score_type_id" },
        { field: "scores.length", key: "g", title: "HS count",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <span>
                          { this.eventList[rowIndex].scores.length }
                      </span>
                  );
              }
            },
        {field: "",
            key: "e",
            title: "Action",
            width: "",
            center: "left",
            renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                    <span>
                        <button
                            class="button-demo"
                            on-click={() => this.showEventByIndex(rowIndex)}
                        >
                        show: {rowIndex}
                        </button>
                        &nbsp;
                        <button
                            class="buttonAchtung"
                            on-click={() => this.deleteEvent(this.eventList[rowIndex])}
                        >
                            delete: {rowIndex}
                        </button>

                      </span>
                  );
              },
          },

    ],
      showDeleteOneWin: false,
      showEditWin : false,
      showHsWin: false,
            new_Event_name : "",
            new_Event_initscore : 0, 
             newEventName:"",
             showMore: false,
             showHsWin:   false,
             nrlist: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],

      currentEvent: null,
      newEventName: '',
    }
  },

   computed:{
     ...mapGetters([
       'eventList',
     ]),
   },
   async mounted() {
     await this.getEvents()
     this.ok = true
   },

   methods: {
    
    ...mapActions([
      'getEvents',
      'newEventByName',
      'deleteEvent'
    ]),

    showEventByIndex: function (i) {
      //let _eventName = _event.name
      console.log(this.$options.name + " -> showEvent -> ", i)
     // this.currentEvent = _event
     // this.showHsWin = true
     this.$router.push('/event/' + this.eventList[i].name) 
    },

  },

}

</script>

<style scoped lang="scss">

div {
  color: #35d4c7;
  font-size: 1.1rem;
}
  
#eventContainer {
        position: relative;
        display: flex;
        background-color: black;
}

.csInput{
      padding: 1vw 1vw;
    font-size: 1.1rem;
    margin: 1vw;
}
th {
  color: #35d4c7;
}
#eventList {
    font-size: 3rem; 
    position: relative;
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: hidden;

  div {
     padding-top: 1vh;
  }

}

</style>
